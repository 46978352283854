"use client";

import {
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  useColorModeValue,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
} from "@chakra-ui/react";
import { redirectIfNotAuthorized, useTranslation } from "src/lib/utils";
import useCurrentUser from "src/components/useCurrentUser";
import NotifsWidget from "./notifs-widget";
import Link from "src/renderer/link";
import T from "src/renderer/local-text";
import LocalePicker from "./locale-picker";

export const NavLink = (props: Props) => {
  const { children, href, isExternal } = props;
  let isActive = false;
  if (typeof window !== "undefined") {
    isActive = window.location.pathname === href;
  }

  return (
    <Link
      target={isExternal ? "_blank" : undefined}
      href={href}
    >
      <Box
        fontWeight="bold"
        px={2}
        py={1}
        // rounded={"md"}
        // bg={isActive ? "brand.50" : "inherit"}
        color={isActive ? "brand.500" : "inherit"}
        borderBottom={isActive ? "2px" : "0"}
        _hover={{
          textDecoration: "none",
          color: "brand.500",
          // bg: useColorModeValue("brand.50", "brandSecondary.700"),
        }}
      >
        {children}
      </Box>
    </Link>
  );
};


const MobileNav = ({ links, height, logo, ...rest }: MobileProps) => {
  const t = useTranslation();
  const { data: currentUser, error } = useCurrentUser();
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const bg = useColorModeValue("white", "gray.900");

  if (!currentUser) {
    if (error) {
      redirectIfNotAuthorized(error);
    }
    return "";
  }
  const fullname = `${currentUser.firstname} ${currentUser.lastname}`;
  return (
    <Flex
      ml={{ base: 0, md: 0 }}
      px={{ base: 4, md: 4 }}
      height={height}
      alignItems="center"
      bg={bg}
      borderBottomWidth="1px"
      borderBottomColor={borderColor}
      justifyContent={{ base: "space-between" }}
      {...rest}
    >
      <Box >
        <Link href='/'>
          <Image h='44px' src={logo ?? '/logo-black.svg'} alt='logo' />
        </Link>
      </Box>
      <HStack spacing={8} alignItems={"center"}>
        <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
          {links.map((link) => (
            <NavLink href={link.href} key={`top-${link.name}`}>
              <T>{link.name}</T>
            </NavLink>
          ))}
        </HStack>
      </HStack>
      <HStack spacing={{ base: "2", md: "6" }}>
        <NotifsWidget />
        <Box>
          <LocalePicker />
        </Box>
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} name={fullname} />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{fullname}</Text>
                  {/* <Text fontSize="xs" color="gray.600">
                    Admin
                  </Text> */}
                </VStack>
                {/* <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box> */}
              </HStack>
            </MenuButton>
            <MenuList bg={bg} borderColor={borderColor}>
              {links.map((link) => (
                <Box key={link.href} display={{ md: "none" }}>
                  <Link href={link.href}>
                    <MenuItem>{t(link.name)}</MenuItem>
                  </Link>
                </Box>
              ))}
              <Link href="/account">
                <MenuItem><T>menu:account</T></MenuItem>
              </Link>
              <MenuDivider />
              <Link href="/auth/logout?next=/&auth=0">
                <MenuItem><T>menu:signout</T></MenuItem>
              </Link>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

export default MobileNav;
